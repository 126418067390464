<template>
	<Chart :width="width" :height="height" :widgetData="widgetData" :draw="draw" :drawTooltips="drawTooltips" @refreshData="(p)=>{$emit('refreshData', p)}"/>
</template>

<script>
	import * as d3 from "d3";
	import Chart from "@/components/widgets/Chart"
	export default {
		name: "BarChart",
		props: ['width', 'height', 'widgetData'],
		components:  {Chart},
		data(){
			return {
				
			}
		},
		methods: {
			drawGradiant(line, color,index){
				let id = Math.random().toString(36).slice(2);
				let gradient = line.append('defs')
						.append('linearGradient')
						.attr('id', id )
						.attr('x1', '0')
						.attr('x2', '0')
						.attr('y1', '1')
						.attr('y2', '0')
				gradient.append('stop')
						.attr('offset', '0%')
						.attr('stop-color', color)
						.attr("stop-opacity", 1)

				gradient.append('stop')
						.attr('offset', '0%')
						.attr('stop-color', color)
						.attr("stop-opacity", 0)
						.transition()
						.delay(500+(index*100))
						.duration(1000)
						.attr('offset', '100%')
						.attr("stop-opacity", 0.5)
				return id
			},
			draw(chart, points, color, index){
				let barW = (chart.width - 2*chart.margin) / (points.length * (chart.data.length))
				points.slice(1, points.length - 1).forEach(d=>{
					let line = chart.units.append('g')
					line.append('rect')
						.attr('fill', "url(#"+this.drawGradiant(line, color, index)+")")
						.attr("width", barW)
						.attr("height", chart.height-2*chart.margin - chart.y(d.y))
						.attr("x", index*barW + chart.x(d.x) - barW / 2)
						.attr("y", chart.y(d.y) )	
				})

			},
			drawTooltips(chart, points, color, index){
				var div = d3.select("body").append("div")
					.attr("class", 'tooltip')
					.style("opacity", 0)
					.style("display", "none")

				let barW = (chart.width - 2 * chart.margin) / points.length / (chart.data.length)

				points.slice(1, points.length - 1).forEach((e) => {
					let startx = index*barW + chart.x(e.x) - barW / 2

					chart.points.append('path')
						.datum([{x: startx, y: chart.y(e.y) },{x: startx + barW, y: chart.y(e.y)}])
						.attr("stroke", color)
						.attr("stroke-width", 4)
						.attr('d', d3.line()
							.x((d) => { return d.x })
							.y((d) => { return d.y })
						)
						.style("cursor", "pointer")
						.on("mouseover", (ev)=>{
							div.style('display', 'block')
								.transition()
								.duration(200)
								.style("opacity", .9);
							div.text(parseInt(e.y))
								.style("left", (ev.pageX + 14) + "px")
								.style("top", (ev.pageY - 18) + "px")
								.style("color", color);
						})
						.on("mouseout",()=>{
							div.transition()
								.duration(500)
								.style("opacity", 0);
						})
						.attr("transform", "translate(0 "+ (chart.height - 2 * chart.margin) +")")
						.transition()
						.delay(100 * index)
						.duration(800)
						.attr("transform", "translate(0 0)")
				})
			}
		},
		mounted(){
		}
	}
</script>

<style scoped>

</style>
