<template>
	<svg :width="width" :height="height "/>
</template>

<script>
	import * as d3 from "d3";

	export default {
		name: "Chart",
		props: ['width', 'height', 'widgetData', 'draw', 'drawTooltips', "xAxisType"],
		data(){
			return {
				svg: null,
				margin: 20,
				x: null,
				y: null,
				ready: false,
				viewport: null,
				units: null,
				axes: null,
				brush: null,
				tooltips: null,
				points: null,
				thresholds: null,
			}
		},
		computed: {
			data(){
				if (this.widgetData){
					let res = []

					this.widgetData.items.forEach(item => {
						let line = item.values.map(e => { return {x: parseFloat(e[0]) * 1000, y: parseFloat(e[1])} })
						line.unshift({x: line[0].x, y:0})
						line.push({x:line[line.length - 1].x, y:0})
						res.push(line)
					})

					return res
				}
				else{
					return  []
				}
			},
			params(){
				if (this.widgetData){
					return this.widgetData.params
				}else{
					return  {}
				}
			},
			thresholdsList(){
				if (this.widgetData){
					return this.widgetData.thresholds
				}else{
					return  []
				}
			}

		},
		watch: {
			width(){
				this.initSvg()
			},
			widgetData(val){
				if(val){
					this.initSvg()
				}
			}
		},
		methods: {
			randomColor(){
				var letters = '456789ABCDEF';
				var color = '#';

				for (var i = 0; i < 6; i++) {
					color += letters[Math.floor(Math.random() * 12)];
				}

				return color;
			},
			clip(){
				let id = Math.random().toString(36).slice(2);

				this.units
					.attr("clip-path", "url(#"+id+")")
				this.points
					.attr("clip-path", "url(#"+id+")")

				this.viewport.append("clipPath")
					.attr("id", id)
					.append("rect")
					.attr("x", 1)
					.attr("y", 0)
					.attr("width", this.width - 1.8 * this.margin)
					.attr("height", this.height - 2 * this.margin)
			},

			initSvg(){
				this.svg = d3.select(this.$el)
				this.svg.selectAll('g').remove()
				this.viewport = this.svg.append('g')
				this.viewport.attr("transform", `translate(${1.5 * this.margin}, ${this.margin * 0.1})`)
				this.axes = this.viewport.append('g')
				this.units = this.viewport.append('g')
				this.tooltips = this.viewport.append('g')
				this.brush = this.viewport.append('g')
				this.points = this.viewport.append('g')
				this.thresholds = this.viewport.append('g')
				this.clip()
				this.drawBrush()
				this.drawAxes(
					this.data.length > 0 ? this.data[0].map(e => e.x) : [],
					this.data.map(e => Math.max.apply(Math, e.map(k => k.y)) ),
				)
				this.drawThresholds()
				this.data.forEach((line, index) => {
					let color = this.randomColor()

					this.draw(this, line, color, index)
					this.drawTooltips(this, line, color, index)
				})

			},
			drawThresholds(){
				this.thresholdsList.forEach(t=>{
					let line = this.thresholds.append('g')
					let size = this.width-2*this.margin
					new Array(t.max, t.min).forEach(y=>{
						line.append("path")
						.datum([{x:0, y:this.y(y)},{x: size, y:this.y(y)}])
						.attr('fill', 'none')
						.attr('stroke', t.color)
						.attr('stroke-width', '1')
						.attr('d', d3.line()
							.x((d)=>{ return d.x })
							.y((d)=>{ return d.y })
						)
						.attr("stroke-dasharray",  (size)/100+" "+(size)/100)
						.attr("stroke-dashoffset", size/50)
						.transition()
						.on("start", function repeat(){
							d3.active(this)
								.transition()
								.ease(d3.easeLinear)
								.duration(500)
								.attr("stroke-dashoffset", 0)
								.transition()
								.duration(0)
								.attr("stroke-dashoffset", size/50)
								.on("end", repeat)
						})
					})
					
				})
			},
			drawAxes(xAxis, yAxis){
				if(this.xAxisType){
					this.x = this.xAxisType
				}
				else {
					this.x = d3.scaleTime()

				}

				this.x.domain(d3.extent(xAxis, function(d) { return  d }))
					.range([0, this.width - 2.1 * this.margin])

				this.axes.append('g')
				    .attr("transform", "translate(0," + (this.height - 2*this.margin) + ")")
					.style("color", "var(--v-black2white-base)")
					.call(d3.axisBottom(this.x).ticks((this.width - 2.1 * this.margin) / 100))

			 	this.y = d3.scaleLinear()
					.domain([0, d3.max(yAxis, function(d) { return d }) *1.75])
					.range([this.height - 2 * this.margin, 0])

				this.axes.append('g')
					.style("color", "var(--v-black2white-base)")
					.call(d3.axisLeft(this.y).ticks((this.height - 2 * this.margin) / 50))
			},
			drawBrush(){
				let brush = d3.brushX()
					.extent( [ [0, this.margin * 0.1], [this.width - 3 * this.margin, this.height - 2 * this.margin] ] )
					.on("end", (e) => {
						let select = e.selection

						if (select !== null){
							let start = new Date(this.x.invert(select[0])).toISOString()
							let end = new Date(this.x.invert(select[1])).toISOString()

							this.$emit("refreshData", {start: start, end: end})
							this.ready = false
							this.brush.call(brush.move, null)
						}
					})

				this.brush.attr('fill', 'blue')
					.call(brush)

				this.svg.on("dblclick", () => {
					this.$emit("refreshData", {})
					this.ready = false
					this.brush.call(brush.move, null)
				})
			}
		},
		mounted(){
			this.initSvg()
		}
	}
</script>

<style scoped>

</style>
